<template>
	<div>
		<el-dialog :visible="visible" @close="handleClose" title="配置小程序卡片" width="640px">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
				<el-form-item label="选择页面:" prop="url">
					<div>
						<el-link type="primary" @click="linkVisible=true" style="line-height: 18px;">
							<span v-if="ruleForm.name" class="have-name">{{ruleForm.name}}</span>
							<span v-else>请选择链接页面</span>
							<i class="el-icon-arrow-down"></i>
						</el-link>
					</div>
				</el-form-item>
				<el-form-item label="分享描述:" prop="desc">
					<el-input v-model="ruleForm.desc" maxlength="20" placeholder="请输入分享描述,最多20个字" style="width: 400px;">
					</el-input>
				</el-form-item>
				<el-form-item label="分享图片:" prop="imgUrl">
					<el-upload class="avatar-uploader" :action="imgApi" :before-upload="beforeUpload"
						:on-success="handleUploadSuccess" accept="image/png,image/jpeg,image/jpg,image/gif"
						:show-file-list="false">
						<img v-if="ruleForm.imgUrl" :src="imgUrl + ruleForm.imgUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div style="font-size: 12px;color: #999;line-height: 18px;margin-top: -5px;">图片大小请控制在1M以内；图片尺寸建议宽520px,高416px</div>
				</el-form-item>
			</el-form>
		
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="handleSureSelect">确 定</el-button>
			</span>
			
		</el-dialog>
		
		<link-modal :unshowTab="[5]" :unfunctionalTab="[3,7,8,12,14,15]" :visible="linkVisible"
			@change="dataChange" @cancel="linkVisible=false"></link-modal>
	</div>
	
</template>

<script>
	import config from '@/config/index';
	import linkModal from '@/views/components/linkModal';
	
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			defaultData: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		components: {
			linkModal
		},
		data() {
			const imgUrlValid = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请上传分享图片'));
				} else {
					callback();
				}
			};
			const urlValid = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请选择页面'));
				} else {
					callback();
				}
			};
			
			return {
				imgApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				ruleForm: {
					url: '',
					name: '',
					desc: '',
					imgUrl: ''
				},
				rules: {
					desc: [{
						required: true,
						message: '请输入分享描述',
						trigger: 'blur'
					}],
					imgUrl: [{
						required: true,
						trigger: 'change',
						validator: imgUrlValid
					}],
					url: [{
						required: true,
						trigger: 'change',
						validator: urlValid
					}]
				},
				linkVisible: false,
			}
		},
		created() {},
		beforeMount() {},
		mounted() {},
		watch: {
			visible(){
				if (this.visible){
					this.ruleForm.url = this.defaultData.LinkUrl;
					this.ruleForm.name = this.defaultData.LinkName;
					this.ruleForm.desc = this.defaultData.Description;
					this.ruleForm.imgUrl = this.defaultData.ImgUrl;
				}
			}
		},
		methods: {
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!isLt1M) {
					this.$message.error('分享图片大小请控制在1M以内')
					return false
				}
				return true;
			},
			handleUploadSuccess(res, file) {
				this.ruleForm.imgUrl = res[0]
			},
			handleClose() {
				this.ruleForm.name = ''
				this.ruleForm.desc = ''
				this.ruleForm.imgUrl = ''
				this.$refs.ruleForm.resetFields()
				this.$emit('close')
			},
			handleSureSelect() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						var obj = JSON.parse(JSON.stringify(this.ruleForm));
						this.$emit('change', obj);
						this.$refs['ruleForm'].resetFields();
						this.handleClose();
					}
				});
				
			},
			dataChange(record){
				console.log(record)
				this.ruleForm.url = this.getLinkUrl(record);
				this.ruleForm.desc = record.text;
				this.ruleForm.name = record.text;
			},
			getLinkUrl(record) {
				if (record.type == "classify") {
					return 'pages/productGroup/productGroup?id=' + record.data.Id
				} else if (record.type == "functional") {
					// 全部商品
					if (record.data.id == 1) {
						return 'pages/index/index'
					} else if (record.data.id == 2) {
						return 'pages/allProduct/allProduct'
					} else if (record.data.id == 3) {
						// 个人中心
						return 'pages/personalCenter/mine/mine'
					} else if (record.data.id == 4) {
						// 购物车
						return 'pages/shoppingCart/shoppingCart'
					} else if (record.data.id == 5) {
						// 积分中心
						return 'pages/pointsCenter/pointsCenter'
					} else if (record.data.id == 6) {
						// 每日签到
						return 'pages/userSign/userSign'
					} else if (record.data.id == 7) {
						// 客服
						return 'pages/customerService/customerService'
					} else if (record.data.id == 8) {
						// 订单列表
						return 'pages/order/myOrder/myOrder'
					} else if (record.data.id == 9) {
						// 分销礼包
						return 'pages/invitation/invitationCard/invitationCard'
					} else if (record.data.id == 10) {
						// 成为会员
						return 'pageA/pages/openSuccess/openSuccess'
					} else if (record.data.id == 11) {
						// 助力直播列表
						return 'pageA/pages/live/live'
					} else if (record.data.id == 12) {
						// 扫码购
						return 'pageA/pages/scan/scan'
					} else if (record.data.id == 13) {
						// 充值中心
						return 'pageA/pages/balanceRecharge/balanceRecharge'
					} else if (record.data.id == 14) {
						// 群分享
						return 'pageA/pages/groupShare/groupShare'
					} else if (record.data.id == 15) {
						// 全部信息页
						return 'pageA/pages/information/information'
					}else if (record.data.id == 16) {
						// 短视频带货
						return 'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
						// 积分码
						return 'pageA/pages/receive-point/receive-point'
					}else if (record.data.id == 18) {
						// 舌诊
						return 'pageC/pages/tongueDiagnosis/home'
					}else if (record.data.id == 19) {
						// 企店即创主页
						return 'pageC/pages/AIGC/home'
					}
				} else if (record.type == "product") {
					return 'pages/detail/detail?id=' + record.data.Id
				} else if (record.type == "custom") {
					return 'pages/cusPage/cusPage?id=' + record.data.Id
				} else if (record.type == 'truntable') {
					return 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
				} else if (record.type == 'live') {
					return 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
				} else if (record.type == 'coupon') {
					return 'pages/couponCenter/receive/receive?id=' + record.data.Id + '&uid=' + (record.data.uid || '')
				} else if (record.type == 'help') {
					return 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
				} else if (record.type == 'group') {
					return 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data
						.ActivityFullId
				} else if (record.type == 'questionnaire') {
					return 'pageA/pages/questionnaire/questionnaire?&referer=5&id=' + record.data.Id;
				}else if (record.type == 'taketest') {
					return `pageC/pages/goodnessTest/home?id=` + record.data.Id
				}else if (record.type == 'contentgroup') {
					return `pageA/pages/information/informationGroup?id=` + record.data.Id;
				}else if (record.type == 'singlecontent') {
					return `pageA/pages/information/detail?id=` + record.data.Id;
				}else if (record.type == 'helpPro') {
					return `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId
				}
				return ''
			},
		}
	}
</script>

<style lang="less">
	.have-name{
		display: inline-block;
		max-width: 400px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: -2px;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 138px;
		height: 138px;
		line-height: 138px;
		text-align: center;
	}

	.avatar {
		width: 138px;
		height: 138px;
		display: block;
	}
</style>
